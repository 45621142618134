.sub-navigation {
  @include align-self(flex-start);
  @include display(flex);
  @include justify-content(center);
  @include align-items(center);
  @include flex-wrap(wrap);
  @include clearfix();

  width: 100%;
  color: $color-white;
  float: right;
  margin-top: -70px;

  h3 {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 300;
  }

  p {
    margin: 0;
    font-weight: 300;
  }

  @include non-retina {
    h3 {
      font-size: 17px;
    }

    p {
      font-size: 15px;
    }
  }

  @include media(0, $range-palm) {
    display: none;
  }

  @include media($range-desk, false) {
    @include justify-content(flex-end);

    width: 50%;

    h3 {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-size: 17px;
    }
  }

  @include media(0, $range-desk-15) {
    h3 {
      font-size: 17px;
    }

    p {
      font-size: 15px;
    }
  }
}

.sub-navigation__spacer {
  width: 100%;
  background: $color-black;
  height: 10px;
  float: right;
  margin-top: -10px;
}

.sub-navigation__content {
  @include clearfix();

  padding: 25px 1.5rem;

  @include media(0, $range-lap) {
    padding: 15px 1.5rem;
  }
}

.sub-navigation__left {
  width: 100%;
  background: $color-light-blue;
  position: relative;

  @include media($range-lap) {
    .sub-navigation__content {
      padding-left: 13%;
    }
  }

  @include media($range-lap, false) {
    width: 50%;
  }

  .options {
    position: absolute;
    display: none;
    z-index: $z-index-100;
  }

  .option {
    font-size: 13px;
    display: inline-block;
    background: $color-white;
    padding: 20px 20px;
    border-bottom: thin solid #eee;

    &:hover {
      background: darken($color-white, 5) !important;
      @include transition(1.0s);
    }

    @include non-retina {
      font-size: 12px;
    }

    @include media($range-lap) {
      padding-left: 12%;
    }
  }
}

.sub-navigation__right {
  width: 100%;
  background: $color-black;

  h3 {
    position: relative;

    &:after {
      @extend .lnr;
      content: $lnr-arrow-right;
      margin-left: 5px;
      font-weight: bold;
      font-size: 18px;
      position: absolute;
      top: 18%;

      @include media(0, $range-desk-15) {
        top: 10%;
      }
    }
  }

  @include media($range-lap, false) {
    width: 50%;
  }
}