.section--coral {
  .button {
    background-color: $color-white;
    color: $color-coral;

    &:hover {
      background-color: $color-coral;
      color: $color-white;
    }
  }

  .button--primary {
    background-color: $color-coral;
    color: $color-white;

    &:hover {
      background-color: $color-white;
      color: $color-coral;
    }
  }

  a {
    &:hover {
      color: $color-coral;
    }
  }
}