.elements-horizontal-left {
  @include align(flex, left, null);
}
.elements-horizontal-center {
  @include align(flex, center, null);
}
.elements-horizontal-right {
  @include align(flex, right, null);
}

.elements-vertical-top {
  @include align(flex, null, top);
}
.elements-vertical-center {
  @include align(flex, null, center);
}
.elements-vertical-bottom {
  @include align(flex, null, bottom);
}

.small-desk-elements-vertical-top {
  @include media($range-palm, $range-desk-m) {
    @include align(flex, null, top);
  }
}
.small-desk-elements-vertical-center {
  @include media($range-palm, $range-desk-m) {
    @include align(flex, null, center);
  }
}
.small-desk-elements-vertical-bottom {
  @include media($range-palm, $range-desk-m) {
    @include align(flex, null, bottom);
  }
}

.text-align-left {
  @include align(text, left);
}
.text-align-center {
  @include align(text, center);
}
.text-align-right {
  @include align(text, right);
}

.height-10 {
  height: 10%;
}
.height-20 {
  height: 20%;
}
.height-25 {
  height: 25%;
}
.height-30 {
  height: 30%;
}
.height-40 {
  height: 40%;
}
.height-50 {
  height: 50%;
}
.height-60 {
  height: 60%;
}
.height-70 {
  height: 70%;
}
.height-75 {
  height: 75%;
}
.height-80 {
  height: 80%;
}
.height-90 {
  height: 90%;
}
.height-100 {
  height: 100%;
}

.height-600px {
  height: 600px;
}

@include media(0, $range-palm) {
  .mobile-height-10 {
    height: 10%;
  }
  .mobile-height-20 {
    height: 20%;
  }
  .mobile-height-25 {
    height: 25%;
  }
  .mobile-height-30 {
    height: 30%;
  }
  .mobile-height-40 {
    height: 40%;
  }
  .mobile-height-50 {
    height: 50%;
  }
  .mobile-height-60 {
    height: 60%;
  }
  .mobile-height-70 {
    height: 70%;
  }
  .mobile-height-75 {
    height: 75%;
  }
  .mobile-height-80 {
    height: 80%;
  }
  .mobile-height-90 {
    height: 90%;
  }
  .mobile-height-100 {
    height: 100%;
  }
}

.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.width-25 {
  width: 25%;
}
.width-30 {
  width: 30%;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-70 {
  width: 70%;
}
.width-75 {
  width: 75%;
}
.width-80 {
  width: 80%;
}
.width-90 {
  width: 90%;
}
.width-100 {
  width: 100%;
}

.background-black {
  background: $color-black;
  color: $color-white;
}
.background-light-blue {
  background: $color-light-blue;
  color: $color-black;
}
.background-white {
  background: $color-white;
  color: $color-black;
}
.background-light-gray {
  background: $color-light-gray;
  color: $color-black;
}
.background-gray {
  background: $color-lighter-gray;
  color: $color-black;
}

.text-color-white {
  color: $color-white;
}
.text-color-black {
  color: $color-black;
}

.responsive-padding {
  padding-left: $padding-left-and-right;
  padding-right: $padding-left-and-right;

  @include media(0, $range-desk-13) {
    padding-left: $tablet-padding-left-and-right;
    padding-right: $tablet-padding-left-and-right;
  }
}
.responsive-padding-with-gutter {
  padding-left: calc(#{$padding-left-and-right} + 1.5rem);
  padding-right: calc(#{$padding-left-and-right} + 1.5rem);

  @include media(0, $range-desk-13) {
    padding-left: calc(#{$tablet-padding-left-and-right} + 1.5rem);
    padding-right: calc(#{$tablet-padding-left-and-right} + 1.5rem);
  }
}
.responsive-padding-right {
  padding-left: 1.5rem;
  padding-right: $padding-left-and-right;

  @include media(0, $range-desk-13) {
    padding-right: $tablet-padding-left-and-right;
  }
}
.responsive-padding-left {
  padding-left: $padding-left-and-right;
  padding-right: 1.5rem;

  @include media(0, $range-desk-13) {
    padding-left: $tablet-padding-left-and-right;
  }
}
.responsive-padding-right-with-gutter {
  padding-left: 1.5rem;
  padding-right: calc(#{$padding-left-and-right} + 1.5rem);

  @include media(0, $range-desk-13) {
    padding-right: calc(#{$tablet-padding-left-and-right} + 1.5rem);
  }
}
.responsive-padding-left-with-gutter {
  padding-left: calc(#{$padding-left-and-right} + 1.5rem);
  padding-right: 1.5rem;

  @include media(0, $range-desk-13) {
    padding-left: calc(#{$tablet-padding-left-and-right} + 1.5rem);
  }
}

.responsive-padding-bottom {
  padding-bottom: 6%;

  @include media(0, $range-desk-13) {
    padding-bottom: 3%;
  }
}

.responsive-padding-top {
  padding-top: 6%;

  @include media(0, $range-desk-13) {
    padding-top: 3%;
  }
}

.responsive-mobile-padding {
  padding: 6%;
}

.small-responsive-padding-bottom {
  padding-bottom: 3%;
}

.smaller-responsive-padding-bottom {
  padding-bottom: 1%;
}

.tablet-padding-bottom {
  @include media($range-lap) {
    padding-bottom: 3%;
  }
}

.stronger-font-weight {
  font-weight: bolder;
}
.strong-font-weight {
  font-weight: bold;
}
.normal-font-weight {
  font-weight: normal;
}
.light-font-weight {
  font-weight: lighter;
}

.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-top-90 {
  margin-top: 90px;
}
.margin-top-100 {
  margin-top: 100px;
}

@include media(0, $range-palm) {
  .mobile-margin-top-10 {
    margin-top: 10px;
  }
  .mobile-margin-top-20 {
    margin-top: 20px;
  }
  .mobile-margin-top-30 {
    margin-top: 30px;
  }
  .mobile-margin-top-40 {
    margin-top: 40px;
  }
  .mobile-margin-top-50 {
    margin-top: 50px;
  }
  .mobile-margin-top-60 {
    margin-top: 60px;
  }
  .mobile-margin-top-70 {
    margin-top: 70px;
  }
  .mobile-margin-top-80 {
    margin-top: 80px;
  }
  .mobile-margin-top-90 {
    margin-top: 90px;
  }
  .mobile-margin-top-100 {
    margin-top: 100px;
  }
}

.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.margin-bottom-90 {
  margin-bottom: 90px;
}
.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-right-40 {
  margin-right: 40px;
}
.margin-right-50 {
  margin-right: 50px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-right-70 {
  margin-right: 70px;
}
.margin-right-80 {
  margin-right: 80px;
}
.margin-right-90 {
  margin-right: 90px;
}
.margin-right-100 {
  margin-right: 100px;
}

.margin-left-5-rem {
  margin-left: .5rem;
}
.margin-right-5-rem {
  margin-right: .5rem;
}

.margin-left-10 {
  margin-left: 10px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-left-50 {
  margin-left: 50px;
}
.margin-left-60 {
  margin-left: 60px;
}
.margin-left-70 {
  margin-left: 70px;
}
.margin-left-80 {
  margin-left: 80px;
}
.margin-left-90 {
  margin-left: 90px;
}
.margin-left-100 {
  margin-left: 100px;
}

@include media(0, $range-lap) {
  .margin-tablet-left-10 {
    margin-left: 10px !important;
  }
}

.padding-top-10 {
  padding-top: 10px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-40 {
  padding-top: 40px;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-60 {
  padding-top: 60px;
}
.padding-top-70 {
  padding-top: 70px;
}
.padding-top-80 {
  padding-top: 80px;
}
.padding-top-90 {
  padding-top: 90px;
}
.padding-top-100 {
  padding-top: 100px;
}
.padding-top-150 {
  padding-top: 150px;
}

.padding-right-10 {
  padding-right: 10px;
}
.padding-right-20 {
  padding-right: 20px;
}
.padding-right-30 {
  padding-right: 30px;
}
.padding-right-40 {
  padding-right: 40px;
}
.padding-right-50 {
  padding-right: 50px;
}
.padding-right-60 {
  padding-right: 60px;
}
.padding-right-70 {
  padding-right: 70px;
}
.padding-right-80 {
  padding-right: 80px;
}
.padding-right-90 {
  padding-right: 90px;
}
.padding-right-100 {
  padding-right: 100px;
}

.padding-left-10 {
  padding-left: 10px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-30 {
  padding-left: 30px;
}
.padding-left-40 {
  padding-left: 40px;
}
.padding-left-50 {
  padding-left: 50px;
}
.padding-left-60 {
  padding-left: 60px;
}
.padding-left-70 {
  padding-left: 70px;
}
.padding-left-80 {
  padding-left: 80px;
}
.padding-left-90 {
  padding-left: 90px;
}
.padding-left-100 {
  padding-left: 100px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-30 {
  padding-bottom: 30px;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-bottom-60 {
  padding-bottom: 60px;
}
.padding-bottom-70 {
  padding-bottom: 70px;
}
.padding-bottom-80 {
  padding-bottom: 80px;
}
.padding-bottom-90 {
  padding-bottom: 90px;
}
.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-left-8 {
  padding-left: 8px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.no-gutter {
  padding-left: 0;
  padding-right: 0;
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}

.loading {
  width: 50px;
}

.display-flex {
  @include display(flex);
}
.flex-wrap {
  @include flex-wrap(wrap);
}

.align-self-top {
  @include align-self(flex-start);
}
.align-self-bottom {
  @include align-self(flex-end);
}
.align-self-center {
  @include align-self(center);
}

.content-aligner {
  @include display(flex);
  @include align-items(stretch);

  width: 100%;
}

.relative {
  position: relative;
}