$font-size-60:   10px !default;
$font-size-70:   11px !default;
$font-size-80:   12px !default;
$font-size-90:   13px !default;
$font-size-100:  15px !default;
$font-size-200:  17px !default;
$font-size-300:  21px !default;
$font-size-400:  24px !default;
$font-size-500:  30px !default;
$font-size-600:  36px !default;
$font-size-700:  48px !default;
$font-size-800:  60px !default;
$font-size-900:  72px !default;
$font-size-1000: 96px !default;
