$line-height-20:  1em !default;
$line-height-30:  1.0625em !default;
$line-height-40:  1.125em !default;
$line-height-50:  1.1875em !default;
$line-height-60:  1.25em !default;
$line-height-70:  1.3125em !default;
$line-height-80:  1.375em !default;
$line-height-90:  1.4375em !default;
$line-height-100: 1.5em !default;
$line-height-200: 1.5625em !default;
$line-height-300: 1.625em !default;
$line-height-400: 1.6875em !default;
$line-height-500: 1.75em !default;
$line-height-600: 1.8125em !default;
$line-height-700: 1.875em !default;
$line-height-800: 1.9375em !default;
$line-height-900: 2em !default;
