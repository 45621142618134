.contact-form {
  input[type=text], input[type=tel], input[type=email], select {
    padding: 10px 20px;
    border-color: #a0a0a0;
    border-radius: 0;

    &.error {
      border-color: red;
    }

    @include media(0, $range-palm) {
      padding: 5px 20px;
    }
  }

  select option[disabled] {
    color: $color-light-gray;
  }
}

.send-inquiry {
  @include align-self(flex-end);

  font-size: 130%;
  font-weight: lighter;
  border: 0;
  height: 15%;

  @include media(0, $range-palm) {
    @include align-self(flex-start);
    @include justify-content(center);

    height: 50px;
  }
}

.awesomplete {
  width: 100%;
}

label.error {
  display: none !important;
}