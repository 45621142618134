.section--army-green {
  .button {
    background-color: $color-white;
    color: $color-army-green;

    &:hover {
      background-color: $color-army-green;
      color: $color-white;
    }
  }

  .button--primary {
    background-color: $color-army-green;
    color: $color-white;

    &:hover {
      background-color: $color-white;
      color: $color-army-green;
    }
  }

  a {
    &:hover {
      color: $color-army-green;
    }
  }
}