.link-list {
  h4 {
    margin-bottom: 10px;
    font-size: 16px;
    @include media(0, $range-desk-m) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    & + .button--outlined {
      margin-top: 30px;
    }

    a {
      font-size: 15px;
      line-height: 1.2;
      color: rgb(136, 136, 136);
      padding: 2px 0;
      display: inline-block;

      &:hover {
        color: $color-black;
      }

      @include media(0, $range-desk-m) {
        font-size: 10px;
        line-height: 1;
      }
    }
  }

  .button--outlined {
    font-size: 14px !important;

    @include media(0, $range-desk-m) {
      font-size: 12px !important;
    }
  }
}