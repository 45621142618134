@font-face {
  font-family: 'Linearicons-Free';
  src:url('/fonts/Linearicons-Free.eot');
  src:url('fonts/Linearicons-Free.eot#iefix') format('embedded-opentype'),
  url('/fonts/Linearicons-Free.woff2') format('woff2'),
  url('/fonts/Linearicons-Free.woff') format('woff'),
  url('/fonts/Linearicons-Free.ttf') format('truetype'),
  url('/fonts/Linearicons-Free.svg#Linearicons-Free') format('svg');
  font-weight: normal;
  font-style: normal;
}

.lnr {
  font-family: 'Linearicons-Free';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$lnr-home: "\e800";
.lnr-home:before {
  content: $lnr-home;
}
$lnr-apartment: "\e801";
.lnr-apartment:before {
  content: $lnr-apartment;
}
$lnr-pencil: "\e802";
.lnr-pencil:before {
  content: $lnr-pencil;
}
$lnr-magic-wand: "\e803";
.lnr-magic-wand:before {
  content: $lnr-magic-wand;
}
$lnr-drop: "\e804";
.lnr-drop:before {
  content: $lnr-drop;
}
$lnr-lighter: "\e805";
.lnr-lighter:before {
  content: $lnr-lighter;
}
$lnr-poop: "\e806";
.lnr-poop:before {
  content: $lnr-poop;
}
$lnr-sun: "\e807";
.lnr-sun:before {
  content: $lnr-sun;
}
$lnr-moon: "\e808";
.lnr-moon:before {
  content: $lnr-moon;
}
$lnr-cloud: "\e809";
.lnr-cloud:before {
  content: $lnr-cloud;
}
$lnr-cloud-upload: "\e80a";
.lnr-cloud-upload:before {
  content: $lnr-cloud-upload;
}
$lnr-cloud-download: "\e80b";
.lnr-cloud-download:before {
  content: $lnr-cloud-download;
}
$lnr-cloud-sync: "\e80c";
.lnr-cloud-sync:before {
  content: $lnr-cloud-sync;
}
$lnr-cloud-check: "\e80d";
.lnr-cloud-check:before {
  content: $lnr-cloud-check;
}
$lnr-database: "\e80e";
.lnr-database:before {
  content: $lnr-database;
}
$lnr-lock: "\e80f";
.lnr-lock:before {
  content: $lnr-lock;
}
$lnr-cog: "\e810";
.lnr-cog:before {
  content: $lnr-cog;
}
$lnr-trash: "\e811";
.lnr-trash:before {
  content: $lnr-trash;
}
$lnr-dice: "\e812";
.lnr-dice:before {
  content: $lnr-dice;
}
$lnr-heart: "\e813";
.lnr-heart:before {
  content: $lnr-heart;
}
$lnr-star: "\e814";
.lnr-star:before {
  content: $lnr-star;
}
$lnr-star-half: "\e815";
.lnr-star-half:before {
  content: $lnr-star-half;
}
$lnr-star-empty: "\e816";
.lnr-star-empty:before {
  content: $lnr-star-empty;
}
$lnr-flag: "\e817";
.lnr-flag:before {
  content: $lnr-flag;
}
$lnr-envelope: "\e818";
.lnr-envelope:before {
  content: $lnr-envelope;
}
$lnr-paperclip: "\e819";
.lnr-paperclip:before {
  content: $lnr-paperclip;
}
$lnr-inbox: "\e81a";
.lnr-inbox:before {
  content: $lnr-inbox;
}
$lnr-eye: "\e81b";
.lnr-eye:before {
  content: $lnr-eye;
}
$lnr-printer: "\e81c";
.lnr-printer:before {
  content: $lnr-printer;
}
$lnr-file-empty: "\e81d";
.lnr-file-empty:before {
  content: $lnr-file-empty;
}
$lnr-file-add: "\e81e";
.lnr-file-add:before {
  content: $lnr-file-add;
}
$lnr-enter: "\e81f";
.lnr-enter:before {
  content: $lnr-enter;
}
$lnr-exit: "\e820";
.lnr-exit:before {
  content: $lnr-exit;
}
$lnr-graduation-hat: "\e821";
.lnr-graduation-hat:before {
  content: $lnr-graduation-hat;
}
$lnr-license: "\e822";
.lnr-license:before {
  content: $lnr-license;
}
$lnr-music-note: "\e823";
.lnr-music-note:before {
  content: $lnr-music-note;
}
$lnr-film-play: "\e824";
.lnr-film-play:before {
  content: $lnr-film-play;
}
$lnr-camera-video: "\e825";
.lnr-camera-video:before {
  content: $lnr-camera-video;
}
$lnr-camera: "\e826";
.lnr-camera:before {
  content: $lnr-camera;
}
$lnr-picture: "\e827";
.lnr-picture:before {
  content: $lnr-picture;
}
$lnr-book: "\e828";
.lnr-book:before {
  content: $lnr-book;
}
$lnr-bookmark: "\e829";
.lnr-bookmark:before {
  content: $lnr-bookmark;
}
$lnr-user: "\e82a";
.lnr-user:before {
  content: $lnr-user;
}
$lnr-users: "\e82b";
.lnr-users:before {
  content: $lnr-users;
}
$lnr-shirt: "\e82c";
.lnr-shirt:before {
  content: $lnr-shirt;
}
$lnr-store: "\e82d";
.lnr-store:before {
  content: $lnr-store;
}
$lnr-cart: "\e82e";
.lnr-cart:before {
  content: $lnr-cart;
}
$lnr-tag: "\e82f";
.lnr-tag:before {
  content: $lnr-tag;
}
$lnr-phone-handset: "\e830";
.lnr-phone-handset:before {
  content: $lnr-phone-handset;
}
$lnr-phone: "\e831";
.lnr-phone:before {
  content: $lnr-phone;
}
$lnr-pushpin: "\e832";
.lnr-pushpin:before {
  content: $lnr-pushpin;
}
$lnr-map-marker: "\e833";
.lnr-map-marker:before {
  content: $lnr-map-marker;
}
$lnr-map: "\e834";
.lnr-map:before {
  content: $lnr-map;
}
$lnr-location: "\e835";
.lnr-location:before {
  content: $lnr-location;
}
$lnr-calendar-full: "\e836";
.lnr-calendar-full:before {
  content: $lnr-calendar-full;
}
$lnr-keyboard: "\e837";
.lnr-keyboard:before {
  content: $lnr-keyboard;
}
$lnr-spell-check: "\e838";
.lnr-spell-check:before {
  content: $lnr-spell-check;
}
$lnr-screen: "\e839";
.lnr-screen:before {
  content: $lnr-screen;
}
$lnr-smartphone: "\e83a";
.lnr-smartphone:before {
  content: $lnr-smartphone;
}
$lnr-tablet: "\e83b";
.lnr-tablet:before {
  content: $lnr-tablet;
}
$lnr-laptop: "\e83c";
.lnr-laptop:before {
  content: $lnr-laptop;
}
$lnr-laptop-phone: "\e83d";
.lnr-laptop-phone:before {
  content: $lnr-laptop-phone;
}
$lnr-power-switch: "\e83e";
.lnr-power-switch:before {
  content: $lnr-power-switch;
}
$lnr-bubble: "\e83f";
.lnr-bubble:before {
  content: $lnr-bubble;
}
$lnr-heart-pulse: "\e840";
.lnr-heart-pulse:before {
  content: $lnr-heart-pulse;
}
$lnr-construction: "\e841";
.lnr-construction:before {
  content: $lnr-construction;
}
$lnr-pie-chart: "\e842";
.lnr-pie-chart:before {
  content: $lnr-pie-chart;
}
$lnr-chart-bars: "\e843";
.lnr-chart-bars:before {
  content: $lnr-chart-bars;
}
$lnr-gift: "\e844";
.lnr-gift:before {
  content: $lnr-gift;
}
$lnr-diamond: "\e845";
.lnr-diamond:before {
  content: $lnr-diamond;
}
$lnr-linearicons: "\e846";
.lnr-linearicons:before {
  content: $lnr-linearicons;
}
$lnr-dinner: "\e847";
.lnr-dinner:before {
  content: $lnr-dinner;
}
$lnr-coffee-cup: "\e848";
.lnr-coffee-cup:before {
  content: $lnr-coffee-cup;
}
$lnr-leaf: "\e849";
.lnr-leaf:before {
  content: $lnr-leaf;
}
$lnr-paw: "\e84a";
.lnr-paw:before {
  content: $lnr-paw;
}
$lnr-rocket: "\e84b";
.lnr-rocket:before {
  content: $lnr-rocket;
}
$lnr-briefcase: "\e84c";
.lnr-briefcase:before {
  content: $lnr-briefcase;
}
$lnr-bus: "\e84d";
.lnr-bus:before {
  content: $lnr-bus;
}
$lnr-car: "\e84e";
.lnr-car:before {
  content: $lnr-car;
}
$lnr-train: "\e84f";
.lnr-train:before {
  content: $lnr-train;
}
$lnr-bicycle: "\e850";
.lnr-bicycle:before {
  content: $lnr-bicycle;
}
$lnr-wheelchair: "\e851";
.lnr-wheelchair:before {
  content: $lnr-wheelchair;
}
$lnr-select: "\e852";
.lnr-select:before {
  content: $lnr-select;
}
$lnr-earth: "\e853";
.lnr-earth:before {
  content: $lnr-earth;
}
$lnr-smile: "\e854";
.lnr-smile:before {
  content: $lnr-smile;
}
$lnr-sad: "\e855";
.lnr-sad:before {
  content: $lnr-sad;
}
$lnr-neutral: "\e856";
.lnr-neutral:before {
  content: $lnr-neutral;
}
$lnr-mustache: "\e857";
.lnr-mustache:before {
  content: $lnr-mustache;
}
$lnr-alarm: "\e858";
.lnr-alarm:before {
  content: $lnr-alarm;
}
$lnr-bullhorn: "\e859";
.lnr-bullhorn:before {
  content: $lnr-bullhorn;
}
$lnr-volume-high: "\e85a";
.lnr-volume-high:before {
  content: $lnr-volume-high;
}
$lnr-volume-medium: "\e85b";
.lnr-volume-medium:before {
  content: $lnr-volume-medium;
}
$lnr-volume-low: "\e85c";
.lnr-volume-low:before {
  content: $lnr-volume-low;
}
$lnr-volume: "\e85d";
.lnr-volume:before {
  content: $lnr-volume;
}
$lnr-mic: "\e85e";
.lnr-mic:before {
  content: $lnr-mic;
}
$lnr-hourglass: "\e85f";
.lnr-hourglass:before {
  content: $lnr-hourglass;
}
$lnr-undo: "\e860";
.lnr-undo:before {
  content: $lnr-undo;
}
$lnr-redo: "\e861";
.lnr-redo:before {
  content: $lnr-redo;
}
$lnr-sync: "\e862";
.lnr-sync:before {
  content: $lnr-sync;
}
$lnr-history: "\e863";
.lnr-history:before {
  content: $lnr-history;
}
$lnr-clock: "\e864";
.lnr-clock:before {
  content: $lnr-clock;
}
$lnr-download: "\e865";
.lnr-download:before {
  content: $lnr-download;
}
$lnr-upload: "\e866";
.lnr-upload:before {
  content: $lnr-upload;
}
$lnr-enter-down: "\e867";
.lnr-enter-down:before {
  content: $lnr-enter-down;
}
$lnr-exit-up: "\e868";
.lnr-exit-up:before {
  content: $lnr-exit-up;
}
$lnr-bug: "\e869";
.lnr-bug:before {
  content: $lnr-bug;
}
$lnr-code: "\e86a";
.lnr-code:before {
  content: $lnr-code;
}
$lnr-link: "\e86b";
.lnr-link:before {
  content: $lnr-link;
}
$lnr-unlink: "\e86c";
.lnr-unlink:before {
  content: $lnr-unlink;
}
$lnr-thumbs-up: "\e86d";
.lnr-thumbs-up:before {
  content: $lnr-thumbs-up;
}
$lnr-thumbs-down: "\e86e";
.lnr-thumbs-down:before {
  content: $lnr-thumbs-down;
}
$lnr-magnifier: "\e86f";
.lnr-magnifier:before {
  content: $lnr-magnifier;
}
$lnr-cross: "\e870";
.lnr-cross:before {
  content: $lnr-cross;
}
$lnr-menu: "\e871";
.lnr-menu:before {
  content: $lnr-menu;
}
$lnr-list: "\e872";
.lnr-list:before {
  content: $lnr-list;
}
$lnr-chevron-up: "\e873";
.lnr-chevron-up:before {
  content: $lnr-chevron-up;
}
$lnr-chevron-down: "\e874";
.lnr-chevron-down:before {
  content: $lnr-chevron-down;
}
$lnr-chevron-left: "\e875";
.lnr-chevron-left:before {
  content: $lnr-chevron-left;
}
$lnr-chevron-right: "\e876";
.lnr-chevron-right:before {
  content: $lnr-chevron-right;
}
$lnr-arrow-up: "\e877";
.lnr-arrow-up:before {
  content: $lnr-arrow-up;
}
$lnr-arrow-down: "\e878";
.lnr-arrow-down:before {
  content: $lnr-arrow-down;
}
$lnr-arrow-left: "\e879";
.lnr-arrow-left:before {
  content: $lnr-arrow-left;
}
$lnr-arrow-right: "\e87a";
.lnr-arrow-right:before {
  content: $lnr-arrow-right;
}
$lnr-move: "\e87b";
.lnr-move:before {
  content: $lnr-move;
}
$lnr-warning: "\e87c";
.lnr-warning:before {
  content: $lnr-warning;
}
$lnr-question-circle: "\e87d";
.lnr-question-circle:before {
  content: $lnr-question-circle;
}
$lnr-menu-circle: "\e87e";
.lnr-menu-circle:before {
  content: $lnr-menu-circle;
}
$lnr-checkmark-circle: "\e87f";
.lnr-checkmark-circle:before {
  content: $lnr-checkmark-circle;
}
$lnr-cross-circle: "\e880";
.lnr-cross-circle:before {
  content: $lnr-cross-circle;
}
$lnr-plus-circle: "\e881";
.lnr-plus-circle:before {
  content: $lnr-plus-circle;
}
$lnr-circle-minus: "\e882";
.lnr-circle-minus:before {
  content: $lnr-circle-minus;
}
$lnr-arrow-up-circle: "\e883";
.lnr-arrow-up-circle:before {
  content: $lnr-arrow-up-circle;
}
$lnr-arrow-down-circle: "\e884";
.lnr-arrow-down-circle:before {
  content: $lnr-arrow-down-circle;
}
$lnr-arrow-left-circle: "\e885";
.lnr-arrow-left-circle:before {
  content: $lnr-arrow-left-circle;
}
$lnr-arrow-right-circle: "\e886";
.lnr-arrow-right-circle:before {
  content: $lnr-arrow-right-circle;
}
$lnr-chevron-up-circle: "\e887";
.lnr-chevron-up-circle:before {
  content: $lnr-chevron-up-circle;
}
$lnr-chevron-down-circle: "\e888";
.lnr-chevron-down-circle:before {
  content: $lnr-chevron-down-circle;
}
$lnr-chevron-left-circle: "\e889";
.lnr-chevron-left-circle:before {
  content: $lnr-chevron-left-circle;
}
$lnr-chevron-right-circle: "\e88a";
.lnr-chevron-right-circle:before {
  content: $lnr-chevron-right-circle;
}
$lnr-crop: "\e88b";
.lnr-crop:before {
  content: $lnr-crop;
}
$lnr-frame-expand: "\e88c";
.lnr-frame-expand:before {
  content: $lnr-frame-expand;
}
$lnr-frame-contract: "\e88d";
.lnr-frame-contract:before {
  content: $lnr-frame-contract;
}
$lnr-layers: "\e88e";
.lnr-layers:before {
  content: $lnr-layers;
}
$lnr-funnel: "\e88f";
.lnr-funnel:before {
  content: $lnr-funnel;
}
$lnr-text-format: "\e890";
.lnr-text-format:before {
  content: $lnr-text-format;
}
$lnr-text-format-remove: "\e891";
.lnr-text-format-remove:before {
  content: $lnr-text-format-remove;
}
$lnr-text-size: "\e892";
.lnr-text-size:before {
  content: $lnr-text-size;
}
$lnr-bold: "\e893";
.lnr-bold:before {
  content: $lnr-bold;
}
$lnr-italic: "\e894";
.lnr-italic:before {
  content: $lnr-italic;
}
$lnr-underline: "\e895";
.lnr-underline:before {
  content: $lnr-underline;
}
$lnr-strikethrough: "\e896";
.lnr-strikethrough:before {
  content: $lnr-strikethrough;
}
$lnr-highlight: "\e897";
.lnr-highlight:before {
  content: $lnr-highlight;
}
$lnr-text-align-left: "\e898";
.lnr-text-align-left:before {
  content: $lnr-text-align-left;
}
$lnr-text-align-center: "\e899";
.lnr-text-align-center:before {
  content: $lnr-text-align-center;
}
$lnr-text-align-right: "\e89a";
.lnr-text-align-right:before {
  content: $lnr-text-align-right;
}
$lnr-text-align-justify: "\e89b";
.lnr-text-align-justify:before {
  content: $lnr-text-align-justify;
}
$lnr-line-spacing: "\e89c";
.lnr-line-spacing:before {
  content: $lnr-line-spacing;
}
$lnr-indent-increase: "\e89d";
.lnr-indent-increase:before {
  content: $lnr-indent-increase;
}
$lnr-indent-decrease: "\e89e";
.lnr-indent-decrease:before {
  content: $lnr-indent-decrease;
}
$lnr-pilcrow: "\e89f";
.lnr-pilcrow:before {
  content: $lnr-pilcrow;
}
$lnr-direction-ltr: "\e8a0";
.lnr-direction-ltr:before {
  content: $lnr-direction-ltr;
}
$lnr-direction-rtl: "\e8a1";
.lnr-direction-rtl:before {
  content: $lnr-direction-rtl;
}
$lnr-page-break: "\e8a2";
.lnr-page-break:before {
  content: $lnr-page-break;
}
$lnr-sort-alpha-asc: "\e8a3";
.lnr-sort-alpha-asc:before {
  content: $lnr-sort-alpha-asc;
}
$lnr-sort-amount-asc: "\e8a4";
.lnr-sort-amount-asc:before {
  content: $lnr-sort-amount-asc;
}
$lnr-hand: "\e8a5";
.lnr-hand:before {
  content: $lnr-hand;
}
$lnr-pointer-up: "\e8a6";
.lnr-pointer-up:before {
  content: $lnr-pointer-up;
}
$lnr-pointer-right: "\e8a7";
.lnr-pointer-right:before {
  content: $lnr-pointer-right;
}
$lnr-pointer-down: "\e8a8";
.lnr-pointer-down:before {
  content: $lnr-pointer-down;
}
$lnr-pointer-left: "\e8a9";
.lnr-pointer-left:before {
  content: $lnr-pointer-left;
}
