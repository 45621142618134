@mixin align($method: absolute, $horizontal: center, $vertical: center, $parent: null) {

	@if $method == 'absolute' {

		@if $parent {
			@at-root #{$parent} {
				position: relative;
			}
		}

		position: absolute;

		@if $horizontal == 'center' and $vertical == 'center' {
			@include transform(translate(-50%, -50%));
			left: 50%;
			top: 50%;
		}

		@else if $horizontal == 'center' {
			@include transform(translateX(-50%));
			left: 50%;
		}

		@else if $vertical == 'center' {
			@include transform(translateY(-50%));
			top: 50%;
		}

		@if $horizontal == 'left' {
			left: 0;
		}

		@else if $horizontal == 'right' {
			right: 0;
		}

		@if $vertical == 'top' {
			top: 0;
		}

		@else if $vertical == 'bottom' {
			bottom: 0;
		}
	}

	@if $method == 'flex' {

		@if $parent {
			@at-root #{$parent} {
				@include display(flex);

				@if $horizontal == 'left' {
					@include justify-content(flex-start);
				}

				@else if $horizontal == 'center' {
					@include justify-content(center);
				}

				@else if $horizontal == 'right' {
					@include justify-content(flex-end);
				}

				@if $vertical == 'top' {
					@include align-items(flex-start);
				}

				@else if $vertical == 'center' {
					@include align-items(center);
				}

				@else if $vertical == 'bottom' {
					@include align-items(flex-end);
				}
			}
		}

		@else {

			@include display(flex);

			@if $horizontal == 'left' {
				@include justify-content(flex-start);
			}

			@else if $horizontal == 'center' {
				@include justify-content(center);
			}

			@else if $horizontal == 'right' {
				@include justify-content(flex-end);
			}

			@if $vertical == 'top' {
				@include align-items(flex-start);
			}

			@else if $vertical == 'center' {
				@include align-items(center);
			}

			@else if $vertical == 'bottom' {
				@include align-items(flex-end);
			}

		}

	}

	@if $method == 'table' {

		@if $parent {
			@at-root #{$parent} {
				display: table;
			}
		}

		display: table-cell;

		@if $horizontal == 'left' {
			text-align: left;
		}

		@else if $horizontal == 'center' {
			text-align: center;
		}

		@else if $horizontal == 'right' {
			text-align: right;
		}

		@if $vertical == 'top' {
			vertical-align: top;
		}

		@else if $vertical == 'center' {
			vertical-align: middle;
		}

		@else if $vertical == 'bottom' {
			vertical-align: bottom;
		}

	}

	@if $method == 'margin' {
		display: block;

		@if $horizontal == 'left' {
			margin-left: 0;
			margin-right: auto;
		}

		@else if $horizontal == 'center' {
			margin-left: auto;
			margin-right: auto;
		}

		@else if $horizontal == 'right' {
			margin-left: auto;
			margin-right: 0;
		}
	}

	@if $method == 'text' {

		@if $horizontal == 'left' {
			text-align: left;
		}

		@else if $horizontal == 'center' {
			text-align: center;
		}

		@else if $horizontal == 'right' {
			text-align: right;
		}
	}
}
