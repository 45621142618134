@mixin link($color: $color-black) {
	border-left: none;
	border-right: none;
	border-top: none;
	border-bottom: 1px solid rgba($color, 0.50);
	color: $color;
	background-color: transparent;
	padding: 0;

	&:hover {
		border-bottom-color: $color;
	}
}
