$font-weight-text-100: 100 !default; // NOTE: hair
$font-weight-text-200: 100 !default; // NOTE: thin
$font-weight-text-300: 300 !default; // NOTE: light
$font-weight-text-400: 300 !default; // NOTE: normal
$font-weight-text-500: 500 !default; // NOTE: medium
$font-weight-text-600: 500 !default; // NOTE: semi-bold/demi-bold
$font-weight-text-700: 700 !default; // NOTE: bold
$font-weight-text-800: 700 !default; // NOTE: black/heavy
$font-weight-text-900: 900 !default; // NOTE: ultra
$font-weight-text-normal: $font-weight-text-400 !default;
$font-weight-text-bold:   $font-weight-text-700 !default;

$font-weight-display-100: 700 !default; // NOTE: hair
$font-weight-display-200: 700 !default; // NOTE: thin
$font-weight-display-300: 700 !default; // NOTE: light
$font-weight-display-400: 700 !default; // NOTE: normal
$font-weight-display-500: 700 !default; // NOTE: medium
$font-weight-display-600: 700 !default; // NOTE: semi-bold/demi-bold
$font-weight-display-700: 700 !default; // NOTE: bold
$font-weight-display-800: 700 !default; // NOTE: black/heavy
$font-weight-display-900: 700 !default; // NOTE: ultra
$font-weight-display-normal: $font-weight-display-400 !default;
$font-weight-display-bold:   $font-weight-display-700 !default;

$font-weight-monospace-100: 400 !default; // NOTE: hair
$font-weight-monospace-200: 400 !default; // NOTE: thin
$font-weight-monospace-300: 400 !default; // NOTE: light
$font-weight-monospace-400: 400 !default; // NOTE: normal
$font-weight-monospace-500: 400 !default; // NOTE: medium
$font-weight-monospace-600: 700 !default; // NOTE: semi-bold/demi-bold
$font-weight-monospace-700: 700 !default; // NOTE: bold
$font-weight-monospace-800: 700 !default; // NOTE: black/heavy
$font-weight-monospace-900: 700 !default; // NOTE: ultra
$font-weight-monospace-normal: $font-weight-monospace-400 !default;
$font-weight-monospace-bold:   $font-weight-monospace-700 !default;