.button {
  @include display(inline-block);

  text-transform: uppercase;
  font-family: 'Avenir LT 65', sans-serif;
  font-size: 12px !important;
  padding: 10px 15px;
  transition: background-color 0.5s ease;

  @include media(0, $range-palm) {
    padding: 5px 10px;
  }

  @include media(0, $range-lap) {

  }
}

.button--primary {
  padding: 13px 18px;

  @include media(0, $range-palm) {
    padding: 10px 15px;
  }
}

.button--outlined {
  position: relative;
  padding: 13px 18px;
  border: 1px solid $color-black;

  &:hover {
    color: $color-white;
    background-color: $color-black;
  }
}

.button--white {
  position: relative;
  padding: 13px 18px;
  background-color: $color-white !important;
  color: $color-black !important;

  &:hover {
    background-color: $color-black !important;
    color: $color-white !important;
  }

  &-arrow-up {
    padding-right: 62px !important;

    &:after {
      @extend .lnr;
      content: $lnr-arrow-up;
      background-color: $color-black;
      color: $color-white;
      padding: 14px 16px;
      display: inline-block;
      margin-left: 18px;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &-arrow-down {
    padding-right: 62px !important;

    &:after {
      @extend .lnr;
      content: $lnr-arrow-down;
      background-color: $color-black;
      color: $color-white;
      padding: 14px 16px;
      display: inline-block;
      margin-left: 18px;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &-arrow-left {
    padding-left: 62px !important;

    &:before {
      @extend .lnr;
      content: $lnr-arrow-left;
      background-color: $color-black;
      color: $color-white;
      padding: 14px 16px;
      display: inline-block;
      margin-right: 18px;
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  &-arrow-right {
    padding-right: 62px !important;

    &:after {
      @extend .lnr;
      content: $lnr-arrow-right;
      background-color: $color-black;
      color: $color-white;
      padding: 14px 16px;
      display: inline-block;
      margin-left: 18px;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  @include media(0, $range-lap) {
    font-size: 10px !important;
    padding: 8px 13px;
    padding-right: 46px !important;
    min-width: 20%;
    text-align: center;

    &:after, &:before {
      padding: 9px 11px;
    }
  }
}

.icon-background-gray {
  &:after, &:before {
    background: $color-light-gray !important;
    color: $color-white !important;
  }
}

.button--black {
  position: relative;
  padding: 13px 18px;
  background-color: $color-black !important;
  color: $color-white !important;

  &:hover {
    background-color: $color-white !important;
    color: $color-black !important;
  }

  &-arrow-up {
    padding-right: 62px !important;

    &:after {
      @extend .lnr;
      content: $lnr-arrow-up;
      background-color: $color-white;
      color: $color-black;
      padding: 14px 16px;
      display: inline-block;
      margin-left: 18px;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &-arrow-down {
    padding-right: 62px !important;

    &:after {
      @extend .lnr;
      content: $lnr-arrow-down;
      background-color: $color-white;
      color: $color-black;
      padding: 14px 16px;
      display: inline-block;
      margin-left: 18px;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &-arrow-left {
    padding-left: 62px !important;

    &:before {
      @extend .lnr;
      content: $lnr-arrow-left;
      background-color: $color-white;
      color: $color-black;
      padding: 14px 16px;
      display: inline-block;
      margin-right: 18px;
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  &-arrow-right {
    padding-right: 62px !important;

    &:after {
      @extend .lnr;
      content: $lnr-arrow-right;
      background-color: $color-white;
      color: $color-black;
      padding: 14px 16px;
      display: inline-block;
      margin-left: 18px;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  @include media(0, $range-lap) {
    font-size: 10px !important;
    padding: 8px 13px;
    padding-right: 46px !important;
    min-width: 20%;
    text-align: center;

    &:after, &:before {
      padding: 9px 11px;
    }
  }
}