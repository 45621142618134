@font-face {
  font-family: 'Avenir LT 65';
  src: url('/fonts/Avenir/AvenirLT-Black.eot');
  src: url('/fonts/Avenir/AvenirLT-Black.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Avenir/AvenirLT-Black.woff2') format('woff2'),
  url('/fonts/Avenir/AvenirLT-Black.woff') format('woff'),
  url('/fonts/Avenir/AvenirLT-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.menu {
  @include clearfix();

  position: fixed;
  z-index: $z-index-10;
  width: 100%;
  left: 0;
  top: 0;
}

.menu__wrapper {
  @include clearfix();
  @extend .responsive-padding;

  background: $color-white;

  //@include media(0, $range-lap) {
  //  padding-left: 0 !important;
  //  padding-right: 0 !important;
  //}
}

.menu__item-wrapper {
  @include display(flex);
  @include align-items(center);
  @include justify-content(flex-start);

  list-style: none;
  padding: 0 0 15px 0;
  margin: 0;
  color: $color-black;
  height: $menu-height;
  margin-top: -5px;
  margin-bottom: 5px;

  @include media($range-lap) {
    @include justify-content(flex-end);
  }

  @include media($range-lap, false) {
    padding: 10px 0;
    width: 100%;
  }
}

.menu__item {
  @include display(inline-block);

  font-family: 'Avenir LT 65', sans-serif;
  font-size: 13px;

  &.active, & a:hover {
    color: $color-coral;
  }

  &.show-on-tablet {
    display: none;
  }

  @include non-retina {
    font-size: 12px;
  }

  @include media($range-lap, $range-desk-15) {
    font-size: 12px;
  }

  @include media(0, $range-lap) {
    font-size: 10px;
    display: none;

    &.show-on-tablet {
      padding-left: 0 !important;
      font-size: 20px;
      display: inline-block;
    }
  }
}

.menu__item-link {
  @include display(inline-block);

  padding-top: 5px;
  padding-bottom: 5px;
}

.menu__item + .menu__item {
  padding-left: 20px;

  &--padding-right {
    padding-right: 20px;
  }

  @include media($range-lap, $range-desk-s) {
    padding-left: 30px;

    &--padding-right {
      padding-right: 30px;
    }
  }

  @include media($range-desk-s, false) {
    padding-left: 50px;

    &--padding-right {
      padding-right: 50px;
    }
  }
}

.menu__logo-wrapper {
  @include display(flex);
  @include justify-content(center);
  @include align-items(center);

  height: 50px;

  @include media($range-lap, false) {
    height: $menu-height;
    float: left;
    padding-left: 1.5rem;
  }
}

.menu__logo {
  @include display(inline-block);

  width: 150px;

  @include media (0, $range-desk-13) {
    width: 130px;
  }
}