.section-scroller {
  position: absolute;
  z-index: $z-index-30;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);

  @include media(0, $range-hand) {
    left: 0;
  }
}

.section-scroller__menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.section-scroller__menu-item {
  padding: 5px;
  height: 17px;
  width: 30px;
  text-align: center;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;

    .section-scroller__menu-link {
      width: 7px;
      background: $color-coral;
    }
  }

  @include media($range-desk-13) {
    padding: 0;
    height: 15px;

    &.active {
      .section-scroller__menu-link {
        width: 5px;
        background: $color-coral;
      }
    }
  }
}

.section-scroller__menu-link {
  @include transition(width 0.2s ease, background-color 0.2s ease);
  background: $color-black;
  height: 7px;
  width: 20px;
  display: inline-block;

  @include media(0, $range-hand) {
    width: 14px;
  }

  @include media($range-desk-13) {
    height: 5px;
    width: 15px;
  }
}