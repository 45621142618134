input,
textarea,
label,
button,
select,
a {
	-webkit-tap-highlight-color: rgba($color-black, 0);
	-webkit-tap-highlight-color: transparent; /* NOTE For some Androids */
}

* {
  margin-top: 0;
}