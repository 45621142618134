.news-item-wrapper {
  background: #eee;

  @include media(0, $range-lap) {
    padding: 0;
  }
}

.back-button-wrapper {
  background: #eee;
  padding-top: 40px;
  padding-bottom: 40px;
}

.news-item {
  background: white;
  margin-top: -130px;
  margin-left: -50px;
  padding: 0 0 60px 0;
  width: 100%;

  @include media(0, $range-desk-15) {
    line-height: 24px;
  }

  .text {
    padding-left: calc(50px + 1.5rem);
    padding-right: 180px;

    &:first-child {
      padding-top: 40px;
    }

    p {
      font-size: 17px;
      line-height: 27px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  iframe {
    width: 100%;
    height: 400px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: inline-block;
  }

  img.full-width {
    width: 100%;
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @include media(0, $range-lap) {
    margin-left: 0;

    h2.h1 {
      font-size: 24px;
    }

    .text {
      padding-left: calc(#{$tablet-padding-left-and-right} + 1.5rem);
      padding-right: calc(#{$tablet-padding-left-and-right} + 1.5rem);
    }
  }
}

.featured-articles {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;

  h2 {
    font-family: 'Avenir LT 65', sans-serif;
    text-transform: uppercase;
    font-size: 15px;
  }

  i {
    font-size: 12px;
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
  }

  .featured-article {
    color: gray;
    margin-bottom: 20px;
  }

  @include media($range-lap-l, false) {
    padding-left: 50px;
  }

  @include media(0, 1023) {
    display: none;
  }
}