.select-ctr {
  width: 350px;
  position: relative;
  cursor: pointer;

  > a {
    padding: 15px 20px;
    position: absolute;
    width: 100%;
    font-size: 1.25rem;
    cursor: pointer;
  }

  $num: 5;

  @while $num > 0 {
    .input-#{$num} {
      top: #{$num*60}px;
    }
    $num: $num - 1;
  }

  > .input {
    background: #fff;
    color: #777;
    border-radius: 2px;
    box-shadow: 0 2px 15px 3px rgba(0, 0, 0, 0.1)
  }

  > .input.active {
    color: #1D77EF;
  }

  > .input-preview {
    color: rgba(255, 255, 255, .75);;
    position: relative;
    transition: .3s all ease;

    &.active {
      color: rgba(255, 255, 255, 1);
    }

    &:before {
      content: "";
      @include position(absolute, 5px 25% null null);
      width: 20px;
      background: rgba(255, 255, 255, .75);
      -webkit-clip-path: polygon(50% 73%, 0 0, 100% 0);
      clip-path: polygon(50% 73%, 0 0, 100% 0);
      padding: 10px;
      box-sizing: border-box;
      transition: .3s all ease;
    }

    &.active:before {
      background: rgba(255, 255, 255, 1);
      -webkit-clip-path: polygon(50% 0, 0 73%, 100% 73%);
      clip-path: polygon(50% 0, 0 73%, 100% 73%);
    }
  }
}