.slide-scroller {
  position: absolute;
  z-index: $z-index-30;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.slide-scroller__menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slide-scroller__menu-link {
  width: 40px;
  height: 40px;
  display: block;
  color: $color-white;
  text-align: center;
  line-height: 40px;
  background-color: #8c8c8c;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    background: #6a6a6a;
    color: $color-white !important;
  }
}

.slide-scroller__menu-link--control {
  background: $color-white;
  color: $color-black;
  opacity: 1;

  &:hover {
    background: $color-white;
    color: $color-black !important;
  }
}