// Descriptive colors

$color-black: #000000 !default;
$color-gray: #9797A7 !default;
$color-lighter-gray: #c6c6c6 !default;
$color-light-gray: #dddddd !default;
$color-white: #ffffff !default;
$color-light-blue: rgb(166, 196, 221) !default;

// Functional Colors

$color-lime-green: #CDD700 !default;
$color-coral: #FF6969 !default;
$color-blue: #3eaaf5 !default;
$color-navy-blue: #405a5e !default;
$color-army-green: #56714a !default;

// Social Media Colors

$color-facebook: #3b5998;
$color-twitter: #00a0d1;
$color-linkedin: #0e76a8;
$color-youtube: #c4302b;
$color-pinterest: #c8232c;
$color-instagram: #3f729b;
$color-googleplus: #db4a39;