$range-palm   : ( 320,  440);
$range-palm-s : ( 320,  360);
$range-palm-m : ( 361,  400);
$range-palm-l : ( 401,  440);

$range-hand   : ( 441,  767);
$range-hand-s : ( 441,  520);
$range-hand-m : ( 521,  600);
$range-hand-l : ( 601,  767);

$range-lap    : ( 768, 1040);
$range-lap-s  : ( 768,  800);
$range-lap-m  : ( 801,  920);
$range-lap-l  : ( 921, 1040);

$range-desk   : (1041, false);
$range-desk-s : (1041, 1280);
$range-desk-m : (1281, 1440);
$range-desk-l : (1441, false);

$range-desk-13 : (1041, 1439);
$range-desk-15 : (1440, 1679);

$range-desk-container : (1300, false);
