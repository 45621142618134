/*
1. Do not include 'input' selector, this will result in heigher specificity and is more difficult to overrule.
2. Chrome and Safari on OS X allow very limited styling of 'select', unless a border is set.
3. Font size of 16px or heigher disables zooming on form fields with iOS.
*/

[type='color'] /* 1 */,
[type='email'] /* 1 */,
[type='number'] /* 1 */,
[type='password'] /* 1 */,
[type='search'] /* 1 */,
[type='tel'] /* 1 */,
[type='text'] /* 1 */,
[type='url'] /* 1 */,
textarea,
select {
	border: 1px solid #000; /* 2 */
	background-color: #fff;
	color: #000;
	font-family: $font-family-sans-serif;
	font-size: 16px; /* 3 */
	line-height: 1.375em;
	box-shadow: none; // Reset FF box shadow
	-webkit-appearance: none;
	   -moz-appearance: none;
		-ms-appearance: none;
		 -o-appearance: none;
			appearance: none;
}

label[for] {
	cursor: pointer;
}

label * {
	pointer-events: none; // HACK make labels better clickable on (iOS?) touch devices
}