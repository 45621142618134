.section-content {
  @include display(flex);
  @include align-items(center);
  @include justify-content(center);
  @include flex-wrap(wrap);
  height: 100%;
}

.section-content--align-bottom {
  @include align-content(flex-end);
}

.section-content--row {
  @include align-items(initial);
  @include justify-content(initial);

  padding: 0 $padding-left-and-right;

  .section-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.section-content--divided {
  @include display(flex);
  @include justify-content(center);
  @include align-items(center);
}

.section-bg {
  background-size: cover;
  background-position: center center;
}

.section-one, .section-two, .section-three, .section-four, .section-five, .section-six {
  @extend .section-bg;
  background: #dddddd;

  @include media(0, $range-palm) {
    background: #d9e2ef;
  }
  @include media($range-lap) {
    background: #b5c6de;
  }
}

.video-overlay {
  width:100%;height:100%;position: absolute;
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1000;
  overflow: hidden;
}