.hide-on-mobile {
  @include media(0, $range-hand) {
    display: none;
  }

  @media screen and ( max-height: 440px ) {
    display: none;
  }
}

.show-on-mobile {
  display: none;

  @include media(0, $range-hand) {
    display: inherit;
  }

  @media screen and ( max-height: 440px ) {
    display: inherit;
  }
}

.hide-on-tablet {
  @include media($range-lap) {
    display: none;
  }
}

.hide-on-tablet-and-up {
  @include media($range-hand, false) {
    display: none;
  }
}

.hide-on-desktop {
  @include media($range-desk, false) {
    display: none;
  }
}

.hide-on-13inch {
  @include media(0, $range-desk-m) {
    display: none;
  }
}