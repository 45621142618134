h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;

  &.h1 {
    font-size: 2em;
  }
}

h1, .h1 {
  font-size: 2em;
}

p {
  font-size: 15px;
  line-height: 20px;
}

@include media(0, $range-desk-13) {
  p {
    line-height: 15px;
  }
}

@include media($range-desk-13, $range-desk-15) {
  p {
    line-height: 18px;
  }
}

@include media(0, $range-desk-15) {
  p {
    font-size: 12px;
  }

  h2 {
    font-size: 18px;
    &.h1 {
      font-size: 20px;
    }
  }

  h3 {
    font-size: 16px;
    &.h1 {
      font-size: 20px;
    }
  }

  h4 {
    font-size: 14px;
    &.h1 {
      font-size: 20px;
    }
  }

  h1, .h1 {
    font-size: 20px;
  }
}