.js-tab {
  display: none !important;

  &.active {
    display: inherit !important;
  }
}

.tab-navigation {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.tab-navigation__item-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.tab-navigation__item {
  min-width: 10%;
  float: left;
  display: inline-block;
  margin-right: 3px;

  a {
    display: inline-block;
    padding-bottom: 20px;
    padding-right: 30px;
    margin-bottom: 0;
    width: 100%;
    border-bottom: 10px solid transparentize($color-gray, 0.2);
  }

  @include media(0, $range-lap-l) {
    min-width: 15%;
  }
}

.tab-navigation__item.active a {
  border-color: transparentize($color-white, 0.2);
}

.tab-navigation__item + .tab-navigation__item {
  margin-left: -2px;
}