 // Respond
 // ------------------------------------------------------
 // Everything between small and medium:
 // @include respond($break-small, $break-medium) { ... }
 // 
 // Everything up to large:
 // @include respond(0, $break-large) { ... }
 // 
 // Everything beyond large:
 // @include respond($break-large) { ...}
 // ------------------------------------------------------
 // $fixed-break variable is used for IE, and should be set
 // in the _settings.scss file
 // ------------------------------------------------------
 // @param {integer} $min-width, can be 0
 // @param {integer} $max-width, is false by default
 
$fixed-break: false !default;
@mixin respond($min-width, $max-width: false) {
	// If we're outputting for a fixed media query set...
	@if $fixed-break {

		// ...and if we should apply these rules...
		@if $fixed-break >= $min-width and ($max-width == false or $fixed-break <= $max-width) {
			// ...output the content the user gave us.
			@content;
		}

	} @else {
		// Otherwise, output it using a regular media query
		@if $min-width == 0 or $min-width == '0px' {
			@if $max-width == false {
				@content;
			} @else {
				@media screen and (max-width: $max-width) {
					@content;
				}
			}
		} @elseif $max-width == false {
			@media screen and (min-width: $min-width) {
					@content;
			}
		} @else {
			@media screen and (min-width: $min-width) and (max-width: $max-width) {
					@content;
			}
		}
	}
}