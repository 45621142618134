.news-slider {
  @include clearfix();

  a, a:focus, a:hover, a:active{
    outline: 0 !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  &__slide {
    position: relative;
    margin-right: 40px;

    &--large {
      width: 640px;
    }

    &--medium {
      width: 480px;
    }

    &--small {
      width: 320px;
    }

    @include media(0, $range-hand) {
      margin-right: 20px;
    }

    .image {
      height: 446px;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        max-width: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .content {
      padding: 20px;
      height: 106px;
      width: 100%;
      left:0;
      bottom: 0;
      position: absolute;
      color: $color-white;

      h2 {
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      p:last-child {
        margin-bottom: 0;
      }

      &.dark-gray {
        background-color: rgb(60,60,60);
      }
      &.light-gray {
        color: $color-black;
        background-color: rgb(237,237,237);
      }
      &.blue {
        background-color: rgb(146,168,213);
      }
    }
  }

  /* Dots */
  .slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -45px;

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover {
    outline: none;
  }
  .slick-dots li button:hover:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    @include font-awesome-icon($fa-var-circle);
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
  }
}

.archive {
  background: #eeeeee;

  .archive__item {
    color: rgb(111,111,111);
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px dotted $color-black;

    p {
      &.date {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    h4 {
      color: #000;
      margin-bottom: 10px;
    }
  }

  .archive-content {
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 2.4em;
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;
    /* place for '...' */
    margin-right: -1em;
    padding-right: 1em;
  }
  /* create the ... */
  .archive-content:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 10px;
    bottom: 0;
    padding-left: 10px;
    /* IE10+ */
    background-image: -ms-linear-gradient(left, transparent 0%, #eee 40%);

    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(left, transparent 0%, #eee 40%);

    /* Opera */
    background-image: -o-linear-gradient(left, transparent 0%, #eee 40%);

    /* Webkit (Safari/Chrome 10) */
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), color-stop(40, #eee));

    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(left, transparent 0%, #eee 40%);

    /* W3C Markup */
    background-image: linear-gradient(to right, transparent 0%, #eee 40%);
  }
  /* hide ... if we have text, which is less than or equal to max lines */
  .archive-content:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 10px;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: #eee;
  }
}