.section-columns--white {
  background: $color-white;
  color: $color-black;
}

.section-columns--black {
  background: $color-black;
  color: $color-white;
}

.section-columns__content {
  //& > div {
  //  //@include align-self(stretch);
  //}

  .product-spec {
    position: relative;

    .left-icon {
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
      height: 50px;
      width: 50px;
      left: -25px;
      bottom: 12px;

      img {
        zoom: 100%;
      }
    }
  }

  img {
    max-width: 100%;
    margin-bottom: 10px;
    display: inline-block;
  }

  h4 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  @include non-retina {
    h4 {
      font-size: 15px;
    }
  }

  @include media(0, $range-desk-13) {
    img, .left-icon {
      zoom: 70%;
    }
  }

  @include media($range-desk-13) {
    img, .left-icon {
      zoom: 80%;
    }
  }

  @include media($range-desk-15) {
    img, .left-icon {
      zoom: 90%;
    }
  }

  @include media(0, $range-hand) {
    width: 100%;
  }

  @include media($range-desk-13, $range-desk-15) {
    h4 {
      font-size: 16px;
    }
  }

  @include media(0, $range-desk-15) {
    .content-wrapper {
      @include display(flex);

      .image {
        @include display(flex);
        @include justify-content(flex-start);
        @include align-items(flex-start);

        width: 25%;
        margin-right: 10px;
      }

      .content {
        width: 75%;
      }
    }
  }

  @include media(0, $range-desk-13) {
    h4 {
      font-size: 13px;
    }
  }
}

.multiple-items {
  .slick-list {
    width: 100%;
  }

  .slick-arrow {
    width: 30px;
    height: 30px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    color: transparent;
    background: transparent;
    border: none;
    outline: 0 !important;
  }

  .slick-next, .slick-prev {
    &:before {
      @extend .lnr;
      color: $color-black;
      font-size: 18px;
      font-weight: bold;
      opacity: 0.4;
      @include transition(opacity ease 0.5s);
    }

    &:hover {
      &:before {
        opacity: 0.8;
      }
    }
  }

  .slick-slide {
    outline: 0 !important;
  }

  .slick-next {
    right: -3%;

    &:before {
      content: $lnr-chevron-right;
    }
  }

  .slick-prev {
    left: -3%;

    &:before {
      content: $lnr-chevron-left;
    }
  }

  /* Dots */
  .slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -45px;

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover {
    outline: none;
  }
  .slick-dots li button:hover:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    @include font-awesome-icon($fa-var-circle);
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
  }
}

.col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9,
.col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-offset-0, .col-sm-offset-1, .col-sm-offset-10, .col-sm-offset-11, .col-sm-offset-12, .col-sm-offset-2, .col-sm-offset-3, .col-sm-offset-4, .col-sm-offset-5, .col-sm-offset-6, .col-sm-offset-7, .col-sm-offset-8, .col-sm-offset-9 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.small-padding {
  padding-left: 8px;
  padding-right: 8px;

  .col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9,
  .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-offset-0, .col-sm-offset-1, .col-sm-offset-10, .col-sm-offset-11, .col-sm-offset-12, .col-sm-offset-2, .col-sm-offset-3, .col-sm-offset-4, .col-sm-offset-5, .col-sm-offset-6, .col-sm-offset-7, .col-sm-offset-8, .col-sm-offset-9 {
    padding-left: 0;
    padding-right: 0;
  }
}