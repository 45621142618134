.section-header {
  h1, h2.h1, h3.h1, h4.h1 {
    margin: 0;
  }

  h1 + p {
    margin-top: 5px;
  }

  @include media(0, $range-hand) {
    p {
      margin-bottom: 30px;
    }

    p + p {
      margin-top: 30px;
    }
  }

  @include media(0, $range-palm) {
    h1, h2.h1, h3.h1, h4.h1 {
      font-size: 18px;
    }

    p {
      font-size: 13px;
    }
  }

  @include media($range-hand, $range-desk-15) {
    h1, h2.h1, h3.h1, h4.h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
}

.header-with-image {
  @include display(flex);
  @include align-items(stretch);
  @include justify-content(center);

  margin-top: 30px;

  h3 {
    margin: 0;
  }

  &__description, &__image {
    @include display(flex);
    @include align-items(center);
  }

  &__description {
    padding-right: 20px;
    border-right: thin solid $color-white;
    margin-right: 20px;
  }
}