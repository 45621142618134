@mixin margin-center {
	@include align(margin);
}

@mixin absolute-center($direction) {
	@if $direction == 'both' {
		@include align(absolute);
	}
	@if $direction == 'horizontal' {
		@include align(absolute, center, null);
	}
	@if $direction == 'vertical' {
		@include align(absolute, null, center);	}
}

@mixin flex-center($direction) {
	@if $direction == 'both' {
		@include align(flex);
	}
	@if $direction == 'horizontal' {
		@include align(flex, center, null);
	}
	@if $direction == 'vertical' {
		@include align(flex, null, center);
	}
}